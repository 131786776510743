import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/holdings/')

/**
 * @api GET /api/holdings/${holdingId}/terms-of-service
 *
 * @param {Number} holdingId
 */
const getTermsOfService = holdingId => {
	const url = `${holdingId}/terms-of-service`
	return axios.get(url)
}

/**
 * @api POST /api/holdings/${holdingId}/terms-of-service
 *
 * @param {Number} holdingId
 * @param {Object} data
 */
const setTermsOfService = (holdingId, data) => {
	const url = `${holdingId}/terms-of-service`
	return axios.post(url, data)
}

export default {
	getTermsOfService,
	setTermsOfService
}
