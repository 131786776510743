<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable no-irregular-whitespace -->
<template>
	<v-layout v-if="terms" column fill-height scroll-y>
		<w-section sticky title="">
			<template v-slot:header>
				<v-flex v-t="'cgu.welyb_terms_and_conditions'" text-xs-center display-1 my-2 />
			</template>
			<v-btn v-if="$route.name === 'cgu-cgv'" flat @click="() => $router.back()">
				<v-icon left> mdi-arrow-left </v-icon>
				Retour
			</v-btn>
			<v-flex px-5>
				<v-layout column>
					<v-flex v-t="'cgu.preamble'" display-1 my-3 style="text-decoration: underline" />
					<v-flex my-1 subheading>
						La Société Welyb, société par actions simplifiée au capital de 30.048 euros, dont le siège social est situé à BORDEAUX (33000), 90 rue François de
						Sourdis, immatriculée au Registre du Commerce et des Sociétés de BORDEAUX, sous le n°828 902 387, numéro de téléphone 06 25 50 61 18, adresse e-mail
						:
						<a href="mailto:contact@welyb.fr">contact@welyb.fr</a> (ci-après « la Société »), est une société spécialisée dans la conception et le développement
						d’applications internet et mobiles pour le secteur comptable et financier.
					</v-flex>
					<v-flex my-1 subheading>
						Elle est propriétaire d’un logiciel « Welyb » (ci-après « Le Logiciel ») permettant de centraliser à partir de la plateforme accessible à distance «
						<a href="https://app.welyb.fr" target="_blank">app.welyb.fr</a> » (ci-après « la Plateforme ») l’ensemble des informations et outils nécessaires à
						la gestion des dossiers. Le Logiciel est commercialisé sous la marque « Welyb » pour une clientèle finale constituée exclusivement de professionnels
						du chiffre, à savoir expert-comptable et/ou commissaire aux comptes (ci-après « Client »).
					</v-flex>
					<v-flex my-1 subheading>
						Les présentes conditions générales d’utilisation (ci-après « CGU ») régissent les conditions d’utilisation du Logiciel « Welyb ».
					</v-flex>
					<v-flex my-1 subheading>
						Tout Utilisateur doit lire attentivement les présentes CGU avant d’utiliser le Logiciel. Le fait que la Société ne se prévale à un moment donné de
						l’une des quelconques dispositions des CGU ne peut être interprété comme valant renonciation à se prévaloir ultérieurement de celles-ci.
					</v-flex>
					<v-flex my-1 subheading> Le directeur de publication du Site est : Monsieur Benoît Maury. </v-flex>
					<v-flex my-1 subheading>
						La Plateforme « <a href="https://app.welyb.fr" target="_blank">app.welyb.fr</a> » est hébergée par la société OVH, dont le siège est situé 2 rue
						Kellermann - 59100 Roubaix (France).
					</v-flex>
					<v-flex my-1 subheading>
						La Société se réserve le droit de modifier ces CGU à tout moment. Ces modifications sont opposables à compter de leur mise en ligne et ne peuvent
						s'appliquer aux contrats conclus antérieurement. Les CGU sont disponibles depuis le site Internet édité par Welyb et accessibles à l’adresse
						<a href="https://www.welyb.fr" target="_blank">www.welyb.fr</a> (ci-après le « Site »), dans sa version web et mobile.
					</v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 1 - Définitions</v-flex>
					<v-flex my-1 subheading> L’Editeur du Site ou la Société: désigne la SAS WELYB.</v-flex>
					<v-flex my-1 subheading>
						Le Client : désigne la clientèle finale constituée exclusivement de professionnels du chiffre, à savoir expert-comptable et/ou commissaire aux
						comptes.
					</v-flex>
					<v-flex my-1 subheading>
						La Plateforme : désigne la plateforme accessible via l’adresse « <a href="https://app.welyb.fr" target="_blank">app.welyb.fr</a> » et tous ses
						sous-domaines.
					</v-flex>
					<v-flex my-1 subheading> L’Entreprise : désigne la personne morale ou physique et disposant d’un espace dédié sur la Plateforme. </v-flex>
					<v-flex my-1 subheading>
						Le Cabinet : désigne le cabinet d’expertise comptable et/ou de commissariat aux comptes disposant d’un espace dédié sur la Plateforme.
					</v-flex>
					<v-flex my-1 subheading> Formules d’Abonnement : désigne les modalités et coûts d'abonnement au Service Welyb. </v-flex>
					<v-flex my-1 subheading>
						Prestation(s) : désigne tous services en ligne, détaillés dans le bon de commande ou la proposition tarifaire, et proposés en mode SaaS dans le but
						de permettre l’utilisation par le Client du Logiciel et ses fonctionnalités, infrastructures, plateformes en ligne par le biais d’un portail
						accessible via internet.
					</v-flex>
					<v-flex my-1 subheading> L'Utilisateur : désigne toute personne physique ou morale utilisatrice du Logiciel. </v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 2 - Le Service « Welyb »</v-flex>
					<v-flex my-1 subheading>
						<strong>2.1</strong> La Société met à disposition du Client le Logiciel et ses fonctionnalités par le biais de la plateforme «
						<a href="https://app.welyb.fr" target="_blank">app.welyb.fr</a> ». Cette mise à disposition est faite par accès à distance, afin de permettre le
						traitement sur le serveur de la Société des données transmises par le Client (ci-après « le Service »). La Société est à ce titre un fournisseur de
						Software as a Service (Saas) c’est-à-dire un fournisseur de technologies informatiques d’entreprises louées et accessibles en ligne.
					</v-flex>
					<v-flex my-1 subheading>
						<strong>2.2</strong> Les modalités de souscription ainsi que les caractéristiques du Service sont détaillées dans les Conditions Générales de Vente
						« Welyb ».
					</v-flex>
					<v-flex my-1 subheading>
						Tout abonnement au Service « Welyb » implique et emporte l'adhésion pleine et entière aux
						<a href="https://www.welyb.fr/cgvu/" target="_blank">Conditions Générales de Vente « Welyb »</a>.
					</v-flex>
					<v-flex my-1 subheading>
						Les formules d’abonnement au Service « Welyb » sont disponibles et consultables depuis le Site
						<a href="https://www.welyb.fr" target="_blank">www.welyb.fr</a>.
					</v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 3 - Obligations des Parties</v-flex>
					<v-flex headline my-2>3.1 Obligations du Client</v-flex>
					<v-flex my-1 subheading>
						Le Client désignera un interlocuteur unique vis-à-vis du responsable technique de la Société. En cas de changement d’interlocuteur, le Client
						s’engage à communiquer par écrit à la Société le nom et les coordonnées du nouveau responsable dans les meilleurs délais.
					</v-flex>
					<v-flex my-1 subheading> Le Client s’engage à informer par écrit la Société de toute modification concernant sa situation. </v-flex>
					<v-flex my-1 subheading>
						Le Client s'engage à payer le prix du Service fourni par la Société selon les modalités prévues par les CGV. Il reconnaît avoir reçu la fiche
						tarifaire de l'offre « Welyb » et en avoir pris connaissance.
					</v-flex>
					<v-flex my-1 subheading>
						Le Client ne peut en aucun cas céder ou transmettre à un tiers, à titre onéreux ou gratuit, sous quelque forme que ce soit, le bénéfice d’un Service
						souscrit, sans l'accord préalable et écrit de la Société.
					</v-flex>
					<v-flex my-1 subheading> Le Client s'engage à utiliser le Service conformément à l'usage pour lequel il a été défini et commercialisé. </v-flex>
					<v-flex headline my-2>3.2 Obligations de la Société</v-flex>
					<v-flex my-1 subheading>
						La Société s’engage à :
						<ul>
							<li>mettre en place les moyens nécessaires au bon fonctionnement et à l’accessibilité du Service.</li>
							<li>réaliser les Prestations convenues dans la commande.</li>
							<li>au Client toute information nécessaire au bon déroulement de la Prestation.</li>
						</ul>
					</v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 4 - Contact</v-flex>
					<v-flex my-1 subheading>
						Pour toute question ou demande d’information concernant la Plateforme, ou tout signalement de contenu ou d’activités illicites, l’Utilisateur peut
						contacter la Société par courrier électronique à l’adresse suivante : <a href="mailto:contact@welyb.fr">contact@welyb.fr</a> ou adresser un courrier
						recommandé avec accusé de réception à : SAS WELYB - 90 rue François de Sourdis, 33000 Bordeaux.
					</v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 5 - Inscription et connexion au Logiciel</v-flex>
					<v-flex my-1 subheading>
						<strong>5.1</strong> L’utilisation du Logiciel nécessite la création d’un compte sur le Logiciel. Pour ce faire, l’Utilisateur doit avoir reçu une
						invitation (soit par le biais de salariés de la Société, soit par le biais d’un autre Utilisateur).
					</v-flex>
					<v-flex my-1 subheading>
						L’Utilisateur garantit que les données communiquées sur sa personne et son état civil notamment à la Société aux fins de la création de son compte
						Utilisateur sont exactes.
					</v-flex>
					<v-flex my-1 subheading>
						L’Utilisateur devra en outre procéder à une vérification régulière des données le concernant afin d’en conserver l’exactitude.
					</v-flex>
					<v-flex my-1 subheading>
						Après avoir procédé à son inscription, l’Utilisateur reçoit un courrier électronique lui demandant de créer un mot de passe.
					</v-flex>
					<v-flex my-1 subheading>
						Après l’inscription par l'Utilisateur, la Société ouvre au Cabinet un accès à la Plateforme permettant ensuite au Cabinet d’ouvrir des accès à ses
						clients qui peuvent être constitués de personne physiques ou morales.
					</v-flex>
					<v-flex my-1 subheading>
						La Société créera pour chaque Cabinet après son inscription, un sous-nom de domaine (moncabinet.welyb.fr), sans que cette mise à disposition pour la
						durée du contrat n'emporte quelque cession de droits que ce soit au Cabinet sur le nom de domaine
						<a href="https://www.welyb.fr" target="_blank">www.welyb.fr</a>, lequel demeure la propriété de la Société tant pour le nom principal que pour les
						sous-noms de domaines qui pourront être créés.
					</v-flex>
					<v-flex my-1 subheading>
						La Plateforme est accessible depuis tout navigateur web, à l’exception du navigateur « Internet Explorer » et une navigation par ce système
						d’exploitation peut provoquer des dysfonctionnements.
					</v-flex>
					<v-flex my-1 subheading>
						Préalablement à son inscription, l’Utilisateur s’engage à prendre connaissance des Pré-requis techniques préconisés par la Société, à les mettre en
						œuvre et à la respecter pour une utilisation conforme du logiciel, savoir :
						<ul>
							<li>
								Disposer d'une connexion internet, si possible stable et fournissant un débit minimum de 2MBits pour l'envoi et la réception de documents ;
							</li>
							<li>Utiliser un navigateur internet moderne et à jour ;</li>
							<li>Utiliser un système d'exploitation à jour: Windows 7 ou ultérieur, MacOS 10.14 ou ultérieur, Linux kernel 4.4 ou ultérieur.</li>
						</ul>
					</v-flex>
					<v-flex my-1 subheading>
						L’Utilisateur est propriétaire des données et informations déposées sur la Plateforme. En cas de perte de mot de passe, ce dernier peut être
						récupéré par demande de l’Utilisateur sur la Plateforme.
					</v-flex>
					<v-flex my-1 subheading>
						<strong>5.2</strong> La Société informe le Client de son inscription par courrier électronique, avec un lien pour la valider. Une fois le compte
						Utilisateur créé, l’Utilisateur devra renseigner son identifiant et son mot de passe afin de se connecter à son compte. Les présentes CGU sont
						réputées acceptées par l'Utilisateur lors de l'enregistrement de son compte Utilisateur et de son identification.
					</v-flex>
					<v-flex my-1 subheading>
						<strong>5.3</strong> L’Utilisateur s’engage à garder ses identifiants confidentiels et personnels. La Société ne saurait être tenue responsable de
						la perte de ses identifiants. L’Utilisateur est seul responsable de l’utilisation frauduleuse ou non par des tiers de ses identifiants.
						L’Utilisateur accepte donc et reconnaît être responsable du respect du caractère confidentiel des identifiants associés à son compte Utilisateur.
					</v-flex>
					<v-flex my-1 subheading> <strong>5.4</strong> Création d’un compte « Entreprise » </v-flex>
					<v-flex my-1 subheading>
						La création d’un compte « Entreprise » se fait en renseignant son nom sur la Plateforme et en rapprochant ses informations avec la base Siret/Siren
						synchronisée.
					</v-flex>
					<v-flex my-1 subheading>
						Les fonctionnalités proposées au Client sur la Plateforme sont la gestion automatisée de la comptabilité, le stockage et la collecte de tout
						document relatif à la gestion et l’organisation de la gestion administrative et des relations avec le client final.
					</v-flex>
					<v-flex my-1 subheading>
						Afin de créer un compte « Entreprise » sur la plateforme, l’Utilisateur certifie qu’il dispose de l’une des relations suivantes avec le Client (le
						nom respect de ces conditions pourra être sanctionné par la suppression du compte de l’Utilisateur) :
						<ul>
							<li>Mandataire social.</li>
							<li>Salarié responsable de missions administratives au sein de l’entreprise.</li>
							<li>Expert-comptable signataire de l’entreprise.</li>
							<li>Salarié du cabinet d’expertise comptable de l’entreprise.</li>
							<li>Avoir reçu mandat de l’entreprise de gérer une partie des missions administratives de l’entreprise sur la plateforme.</li>
						</ul>
					</v-flex>
					<v-flex my-1 subheading> <strong>5.5</strong> Création d’un compte « Cabinet » </v-flex>
					<v-flex my-1 subheading>
						La création d’un compte « Cabinet » est réalisé par la Société en renseignant le nom du Cabinet sur la Plateforme et en rapprochant ses informations
						avec la base Siret/Siren synchronisée.
					</v-flex>
					<v-flex my-1 subheading>
						Les fonctionnalités proposées au Cabinet sur la Plateforme sont l’accès aux documents de ses entreprises clientes, l’assistance à la gestion de sa
						relation client et de l’organisation de son cabinet.
					</v-flex>
					<v-flex my-1 subheading>
						A fin de créer un compte « Cabinet » sur la plateforme, l’Utilisateur certifie qu’il dispose de l’une des relations suivantes avec le cabinet (le
						nom respect de ces conditions pourra être sanctionné par la suppression du compte de l’Utilisateur) :
						<ul>
							<li>Mandataire social</li>
							<li>Salarié</li>
						</ul>
					</v-flex>
					<v-flex my-1 subheading>
						<strong>5.6</strong> Pour les identifiants de connexion aux connecteurs de facturation il appartient au Client de se référer aux conditions
						d’utilisation de l’API (Application Programming Interface) « Budgea Bill » fourni par la société « Budget Insight » dont les informations sont
						consultables sur le site <a href="https://www.budget-insight.com/budgea-api" target="_blank">https://www.budget-insight.com/budgea-api</a>.
					</v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 6 : Utilisation du Logiciel « Welyb »</v-flex>
					<v-flex my-1 subheading>
						<strong>6.1</strong> Afin d’accéder au Logiciel, l’Utilisateur se connecte sur la Plateforme via le lien
						<a href="https://app.welyb.fr/signin" target="_blank">https://app.welyb.fr/signin</a> en renseignant ses identifiants (nom d’utilisateur et mot de
						passe).
					</v-flex>
					<v-flex my-1 subheading> La souscription d’un abonnement payant est requise aux fins de bénéficier du Logiciel et de ses fonctionnalités. </v-flex>
					<v-flex my-1 subheading>
						<strong>6.2</strong> Le Logiciel est divisé en trois (3) produits principaux : un portail en marque blanche, une Gestion Electronique des Documents
						(« GED ») et un outil de gestion interne. Chaque produit peut être vendu de manière indépendante.
					</v-flex>
					<v-flex my-1 subheading>
						Il est précisé que la souscription à la formule d’abonnement « Portail » est un prérequis nécessaire pour bénéficier de toute autre formule
						d’abonnement ou toutes autres fonctionnalités complémentaires.
					</v-flex>
					<v-flex my-1 subheading>
						La Société peut fournir au Client des solutions de paramétrage plus avancées, et notamment une GED automatisée pour un nombre limité de dossiers, la
						gestion interne, le développement d’une application en marque blanche aux couleurs du Client, une formation d’une demi-journée sur site par les
						équipes de la Société, la synchronisation de la GED du cabinet avec déploiement et une prestation de maintenance.
					</v-flex>
					<v-flex my-1 subheading>
						<strong>6.3</strong> L’Utilisateur déclare avoir conscience que les réseaux GSM data (3G, 4G ...) des opérateurs de téléphonie mobile sont en
						constante évolution, qu’il existe en outre des zones dans lesquelles les signaux GSM ne sont pas accessibles (tunnels, effets canyon en ville et en
						montagne) et qu’il est donc possible que certaines zones du territoire ne soient pas couvertes par ces réseaux de sorte que l'accès au Logiciel et
						ses fonctionnalités y est perturbé.
					</v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 7 – Mise à disposition des services</v-flex>
					<v-flex my-1 subheading>
						<strong>7.1</strong> A réception de ses identifiants remis par la Société pour accéder au Services, le Client s’engage à prendre connaissance du
						Guide Utilisateur disponible via le lien <a href="https://welyb.zendesk.com" target="_blank">https://welyb.zendesk.com/</a> et à respecter les
						Conditions Générales d’Utilisations Welyb. Les procédures de paramétrage et d’accès sont décrites dans le Guide Utilisateur et doivent être
						strictement respectées par le Client. Le compte du Client donne accès à l’ensemble des services en ligne énoncés dans le Contrat. Les identifiants
						sont destinés à réserver l’accès au Logiciel et à ses fonctionnalités aux Utilisateurs du Client, à protéger l’intégralité des données, ainsi que
						l’intégrité, la disponibilité et la confidentialité des données du Client telles que transmises par les Utilisateurs. Les identifiants sont
						strictement personnels et confidentiels et conservées sous la seule responsabilité du Client qui veille à ce que les Utilisateurs habilités à
						utiliser les services en ligne ne les communiquent à aucun tiers. Toute connexion ou opération effectuée via les identifiants du Client est réputée
						effectuée par lui. La Société ne sera en aucun cas responsable de l’utilisation du Logiciel ou ses fonctionnalités par un tiers au moyen des
						identifiants personnels des Utilisateurs du Client. En cas de perte ou de vol d’un mot de passe, seul l’Administrateur a la faculté de demander la
						réinitialisation de mots de passe.
					</v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 8 – Propriété des données, documents du Client</v-flex>
					<v-flex my-1 subheading>
						L’Entreprise est propriétaire des données, documents et informations déposés sur son espace dédié. Elle en est seule responsable et peut les
						récupérer à tout moment.
					</v-flex>
					<v-flex my-1 subheading>
						Le Cabinet est propriétaire des documents qu’il a produit et déposé sur la plateforme au sein de l’espace dédié du Client. Ces documents sont ceux
						entrant dans le cadre du droit de rétention au sens de l’article 168 du Décret n° 2012-432 du 30 mars 2012 relatif à l'exercice de l'activité
						d'expertise comptable et au sens du code de déontologie des professionnels de l'expertise comptable.
					</v-flex>
					<v-flex my-1 subheading>
						La Société met en œuvre tous les moyens nécessaires à la protection des données, documents et informations présentes sur la Plateforme. Les données
						déposées sur la plateforme sont entièrement cryptées.
					</v-flex>
					<v-flex my-1 subheading>
						L’Utilisateur peut, à tout moment, solliciter la restitution des données et la copie de ces fichiers de données. Pour cela, l’Utilisateur doit
						envoyer une demande de restitution par mail à l’adresse suivante : <a href="mailto:contact@welyb.fr">contact@welyb.fr</a>.
					</v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 9 – Fermeture du Compte</v-flex>
					<v-flex my-1 subheading>
						La fermeture d’un compte à la demande de l’Utilisateur se fait après demande au service client à l’adresse mail suivant :
						<a href="mailto:contact@welyb.fr">contact@welyb.fr</a>
					</v-flex>
					<v-flex my-1 subheading> La fermeture du compte n'entraîne pas la suppression des données personnelles de l’Utilisateur. </v-flex>
					<v-flex my-1 subheading>
						Les données sont conservées pour une durée de cinq (5) ans. A la fin de ce délai, les données sont complètement supprimées.
					</v-flex>
					<v-flex my-1 subheading> La fermeture du compte ne limite pas les droits de la Société à réclamer les paiements dus. </v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 10 – Accessibilité au logiciel</v-flex>
					<v-flex my-1 subheading>
						La Société met en œuvre les solutions techniques à sa disposition pour permettre l’accès à la Plateforme 24 heures sur 24, 7 jours sur 7, sans pour
						autant être tenue à une obligation d'y parvenir. Elle pourra néanmoins à tout moment suspendre, limiter ou interrompre l’accès à la Plateforme afin
						de procéder à des mises à jour, des modifications de son contenu ou toute autre action jugée nécessaire au bon fonctionnement de la Plateforme.
					</v-flex>
					<v-flex my-1 subheading>
						La connexion et la navigation sur la Plateforme valent acceptation sans réserve des présentes Conditions Générales d’Utilisation, quelques soient
						les moyens techniques d’accès et les terminaux utilisés.
					</v-flex>
					<v-flex my-1 subheading>
						Les présentes Conditions Générales d’Utilisation s’appliquent, en tant que de besoin, à toute déclinaison ou extension du Site sur les réseaux
						sociaux et/ou communautaires existants ou à venir.
					</v-flex>
					<v-flex my-1 subheading>
						La Société n'est en aucun cas responsable de ces interruptions et des conséquences qui peuvent en découler pour l'Utilisateur. L'interruption de
						l'accès à la Plateforme ne peut en aucun cas avoir pour conséquence le versement de dommages et intérêts au profit de l'Utilisateur.
					</v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 11 – Gestion du Site et de la Plateforme</v-flex>
					<v-flex my-1 subheading>
						Pour la bonne gestion de la Plateforme, la Société pourra à tout moment :
						<ul>
							<li>
								suspendre, interrompre ou limiter l’accès à tout ou partie de la Plateforme, réserver l’accès à la Plateforme, ou à certaines parties de la
								Plateforme Site, à une catégorie déterminée d’internaute.
							</li>
							<li>
								supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales, ou
								avec les règles de la Nétiquette.
							</li>
							<li>suspendre la Plateforme afin de procéder à des mises à jour.</li>
						</ul>
					</v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 12 – Mesures de sécurité</v-flex>
					<v-flex my-1 subheading>
						La Société accorde une grande importance à la sécurité de ses serveurs afin d’assurer la confidentialité des données fournies aux Utilisateurs. Il
						s’efforce d’assurer une disponibilité maximum de ses services grâce à des mécanismes de surveillance et d’alerte automatisés lors d’une panne ou
						d’un problème interne à ses services.
					</v-flex>
					<v-flex my-1 subheading>
						Les services de la Société sont développés en tenant compte des différentes failles connues (par exemple injection XSS, injection SQL, CSRF…). Une
						veille régulière est pratiquée sur les dernières technologies et les dernières failles qui sont trouvées.
					</v-flex>
					<v-flex my-1 subheading>
						Les serveurs sont mis à jour régulièrement grâce à des procédures automatisées. Le chiffrement des communications entre les serveurs et les
						Utilisateurs est assuré grâce à un certificat vérifié par l’autorité de certification Let’s Encrypt.
					</v-flex>
				</v-layout>

				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 13 – Utilisation des données</v-flex>
					<v-flex my-1 subheading> La Société se réserve le droit d’utiliser les données personnelles des Utilisateurs : </v-flex>
					<v-flex my-1 subheading>
						A des fins de gestion :
						<ul>
							<li>Modération et suivi des comptes profil des Utilisateurs,</li>
							<li>Pour des sollicitations et messages promotionnels de la Société.</li>
						</ul>
					</v-flex>
					<v-flex my-1 subheading>
						A des fins commerciales :
						<ul>
							<li>Pour élaborer des statistiques commerciales,</li>
							<li>Constitution et gestion des fichiers de prospects.</li>
						</ul>
					</v-flex>
					<v-flex my-1 subheading> Les données ne sont utilisées que dans le cadre indiqué dans le registre des traitements de La Société. </v-flex>
					<v-flex my-1 subheading>
						L’Utilisateur peut contacter la Société à l’adresse « <a href="mailto:contact@welyb.fr">contact@welyb.fr</a> » quant aux mesures mises en œuvre pour
						la protection des données personnelles.
					</v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 14 - Liens hypertextes</v-flex>
					<v-flex my-1 subheading>
						Le Site ou la Plateforme peuvent inclure des liens vers d'autres sites web ou d'autres sources internet. Dans la mesure où La Société ne peut
						contrôler ces sites et ces sources externes, elle ne peut être tenue responsable de la mise à disposition de ces sites et autres sources externes,
						et ne peut supporter aucune responsabilité quant au contenu, publicités, produits, services ou tout autre matériel disponible sur ou à partir de ces
						sites ou sources externes. De plus, la Société ne pourra être tenue responsable de tous dommages ou pertes avérées ou alléguées consécutifs ou en
						relation avec l'utilisation ou avec le fait d'avoir fait confiance au contenu, à des biens ou des services disponibles sur ces sites ou ces sources
						externes. Le recours à ces sites par l'Utilisateur relève de la seule responsabilité de ce dernier.
					</v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 15 - Utilisation de cookies</v-flex>
					<v-flex my-1 subheading>
						Le site « <a href="https://www.welyb.fr" target="_blank">www.welyb.fr</a> » utilise des cookies. L'Utilisateur est informé que, lors de ses visites
						sur le Site, un cookie peut s'installer automatiquement sur son logiciel de navigation.
					</v-flex>
					<v-flex my-1 subheading> En visitant le Site, l'internaute accepte l’utilisation de cookies. </v-flex>
					<v-flex my-1 subheading>
						Le cookie est un bloc de données qui ne permet pas d'identifier l'Utilisateur mais sert à enregistrer des informations relatives à la navigation de
						celui-ci sur le Site. L'utilisation de ces cookies a pour finalité de procéder à des analyses de fréquentation, mesures d'audience, des statistiques
						et volumes de fréquentation et d'utilisation des divers éléments composant le Site (rubriques et contenus visités, parcours) etc. afin d'améliorer
						la qualité du Site, ainsi que d’identifier l’Utilisateur lors de sa connexion sur le Site et de faciliter sa participation à certains évènements et
						activités sur le Site.
					</v-flex>
					<v-flex my-1 subheading>
						Lorsque l’Utilisateur visite le site web « <a href="https://www.welyb.fr" target="_blank">www.welyb.fr</a> » ou la Plateforme, ses serveurs
						mémorisent de façon standardisée le nom de fournisseur d’accès internet, la page web à partir de laquelle il visite le Site, les différentes pages
						qu’il consulte sur le Site ainsi que la date et la durée de sa visite.
					</v-flex>
					<v-flex my-1 subheading>
						Les données collectées sont conservées pendant une durée qui n'excède pas la durée nécessaire aux finalités pour lesquelles elles ont été
						collectées.
					</v-flex>
					<v-flex my-1 subheading>
						L’Utilisateur peut cependant s’opposer à la mise en place de ces "cookies" en désactivant cette option dans les paramètres de son navigateur. La
						Société invite alors l’Utilisateur à consulter les instructions de son navigateur à cet effet et informe qu'en cas de blocage de ces cookies
						certaines fonctionnalités du Site peuvent devenir indisponibles.
					</v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 16 - Interdictions</v-flex>
					<v-flex my-1 subheading>
						Il est interdit d'utiliser le Site pour :
						<ul>
							<li>
								Publier, afficher ou exprimer des opinions, avis, contenus obscènes, blasphématoires, haineux ou injurieux, de considérations d’ordre racial,
								ethnique, sexuel, vulgaires ou de mauvais goût,
							</li>
							<li>Tenir des propos diffamatoires, ou attentatoires à la vie privée d'une autre personne</li>
							<li>Transmettre des chaînes de lettres, des « junk mail », du « spam » ou tout message répétitif ou non sollicité (commercial ou autre)</li>
							<li>
								Recueillir ou collecter, de quelque façon que ce soit, des informations concernant des tiers, notamment des adresses e-mail, sans le
								consentement des tiers concernés
							</li>
							<li>Créer ou d'exploiter de fausses identités</li>
							<li>
								Falsifier une adresse e-mail ou un en-tête, ou essayer d'induire en erreur des tiers de quelque façon que ce soit quant à l'identité de
								l'expéditeur ou à l'origine du message
							</li>
							<li>Transmettre tout contenu inapproprié tel que défini ci-dessous</li>
							<li>
								Transmettre tout élément contenant des virus ou tout élément dommageable ou nuisible au Site ou aux tiers ou encore en contravention de la loi
							</li>
							<li>
								Essayer d'accéder de manière non autorisée aux services offerts, à d'autres comptes, systèmes informatiques ou réseaux connectés au service du
								Site, par l'usage de différents mots de passe ou par tout autre moyen, ou interférer avec l'utilisation et la jouissance du service par tout
								autre utilisateur ou avec l'utilisation et la jouissance de services similaires par le biais d'une autre entité.
							</li>
						</ul>
					</v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 17 - Propriété intellectuelle</v-flex>
					<v-flex my-1 subheading>
						La structuration du Site et de la Plateforme mais aussi les textes, graphiques, images, photographies, sons, vidéos et applications informatiques
						qui le composent sont la propriété de la Société et sont protégés comme tels par les lois en vigueur au titre de la propriété intellectuelle.
					</v-flex>
					<v-flex my-1 subheading>
						Toute représentation, reproduction, adaptation ou exploitation partielle ou totale des contenus, marques déposées et services proposés par la
						Plateforme, par quelque procédé que ce soit, sans l’autorisation préalable, expresse et écrite de la Société, est strictement interdite et serait
						susceptible de constituer une contrefaçon au sens des articles L. 335-2 et suivants du Code de la propriété intellectuelle. Et ce, à l’exception des
						éléments expressément désignés comme libres de droits sur la Plateforme.
					</v-flex>
					<v-flex my-1 subheading>
						L’accès au Site et à la Plateforme ne vaut pas reconnaissance d’un droit et, de manière générale, ne confère aucun droit de propriété intellectuelle
						relatif à un élément du Site ou de la Plateforme, lequel demeure la propriété exclusive de la Société.
					</v-flex>
					<v-flex my-1 subheading>
						Il est interdit à l’Utilisateur d’introduire des données sur la Plateforme qui modifieraient ou qui seraient susceptibles d’en modifier le contenu
						ou l’apparence.
					</v-flex>
					<v-flex my-1 subheading>
						La marque « Welyb » appartient exclusivement à la SAS WELYB et a été déposée sous le numéro national 17 4 385 861 le 4 septembre 2017.
					</v-flex>
				</v-layout>
				<v-layout column>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 18 - Langue du contrat - Droit applicable</v-flex>
					<v-flex my-1 subheading>
						De convention expresse entre les parties, les présentes Conditions Générales d’Utilisation sont régies par le droit français.
					</v-flex>
					<v-flex my-1 subheading>
						Elles sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues, seul le texte français ferait foi en
						cas de litige.
					</v-flex>
				</v-layout>
				<v-layout column mb-2>
					<v-flex display-1 my-3 style="text-decoration: underline">Article 19 - Litiges</v-flex>
					<v-flex my-1 subheading>
						Toute contestation relative à l’utilisation du Logiciel et ses fonctionnalités sera soumise aux Tribunaux compétents du ressort du siège social de
						la Société, y compris en cas d’appel en garantie, pluralité de défendeurs, en matière de référé, procédure d’urgence ou conservatoire.
					</v-flex>
				</v-layout>
			</v-flex>
		</w-section>
		<w-section v-if="terms.termsOfUse" sticky title="">
			<template v-slot:header>
				<v-flex text-xs-center display-1 my-2>
					{{ $t('cgu.accounting_firm_terms_and_conditions', { accountingFirm: accountingFirmName }) }}
				</v-flex>
			</template>
			<v-flex px-5 v-html="terms.termsOfUse" />
		</w-section>
		<w-section v-if="terms.termsOfService" sticky title="">
			<template v-slot:header>
				<v-flex text-xs-center display-1 my-2>
					{{ $t('cgu.accounting_firm_terms_of_service', { accountingFirm: accountingFirmName }) }}
				</v-flex>
			</template>
			<v-flex px-5 v-html="terms.termsOfService" />
		</w-section>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import TermsOfServiceModuleGuard from '@/mixins/ModulesGuards/TermsOfService/TermsOfServiceModuleGuard'

export default {
	name: 'TermsOfService',
	mixins: [TermsOfServiceModuleGuard],
	data: function () {
		return {
			terms: {}
		}
	},
	computed: {
		...mapState({
			accountingFirmName: state => state.whitelabel.name
		})
	},
	async created() {
		if (! this.selectedAccountingFirm?.holding_id) {
			return;
		}

		try {
			this.terms = await this.service.getTermsOfService(this.selectedAccountingFirm.holding_id);
		} catch (error) {
			this.terms = {};
		}
	},
}
</script>
