// ============================================================================
// TermsOfServiceService
// ---------------------
// TermsOfService module related services
// ============================================================================

// -------
// Imports
// -------
import API from '@/apis/TermsOfServiceApi'

import { Bus as AppEventBus, Events as AppEvents } from '@/events/AppEvents'

// ---------
// Internals
// ---------

// -------
// Exports
// -------
export default {
	getTermsOfService: function (holdingId) {
		return API.getTermsOfService(holdingId)
			.then(res => res.data.data)
			.then(data => ({
				termsOfService: data.terms_of_service,
				termsOfUse: data.terms_of_use
			}))
	},
	setTermsOfService: function (holdingId, content) {
		return API.setTermsOfService(holdingId, {
			terms_of_service: content.termsOfService,
			terms_of_use: content.termsOfUse
		})
			.then(res => res.data.data)
			.then(data => {
				AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, window.vueInstance.$t('modules.cgu.success'))
				return data.content
			})
	}
}
